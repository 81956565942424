export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
export const OAUTH_CALLBACK_URL = process.env.REACT_APP_OAUTH_CALLBACK_URL || window.location.origin + '/callback'
export const DEV_MODE = process.env.REACT_APP_DEV_MODE || 0
export const SHOP = process.env.REACT_APP_SHOP || ''
export const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN || ''
export const ALLOWED_REDIRECTS = [
  process.env.REACT_APP_OAUTH_CALLBACK_URL,
  'https://dev.orca-app.punwave.com/callback',
  'https://orca-app.punwave.com/callback'
]
