import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import resources from './resources.js'

// todo: local folder底下的zh-TW有改過名稱，到時拿回翻譯檔，要貼回來時，要把folder名稱從zh-tw改為zh-TW
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    fallbackLng: {
      'en-*': ['en'],
      'zh-*': ['zh-TW']
    },
    debug: true, // todo delete，開發模式，顯示 debug 訊息
    interpolation: {
      escapeValue: false
    }
  }
  , (err, t) => {
    if (err) return console.error('i18next initialization failed', err)
    console.log('Detected language:', i18n.language) // todo delete
  })

export default i18n
