import React, { useState, useCallback, useEffect } from 'react'
import { LegacyCard, FooterHelp, Page, Tabs, ButtonGroup, Button, Frame, Layout, SkeletonBodyText } from '@shopify/polaris'
// import { ExternalMinor } from '@shopify/polaris-icons'
import { authInstance } from '../helper'
import { useTranslation } from 'react-i18next'

import Page0 from './Page0'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page6 from './Page6'
import PageAida from './PageAida'
import HelpModal from '../components/HelpModal'
import PrivacyModal from '../components/PrivacyModal'
import CTABanner from '../components/CTABanner'
import LanguageSwitcher from '../components/LanguageSwitcher'

export default function Main () {
  const [selected, setSelected] = useState(0)
  const [appPayMode, setAppPayMode] = useState('free') // 試用版 trail; 免費版 free; 付費版 subscribe(傳過去 aida-app 會變成 paid)
  const [trialDayLeft, setTrialDayLeft] = useState(-1)
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  // const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const isOrcaXAida = String(window.localStorage.getItem('shop')).includes('cyberbiz')
  const { t } = useTranslation('main')

  const getAppSubscriptions = async () => {
    const res = await authInstance.get('/get_app_subscriptions', {})
    if (res.data.payMode) {
      setAppPayMode(res.data.payMode)
      setTrialDayLeft(res.data.trialDayLeft)
    }
    // 要強制測試付費版頁面，把這邊打開
    // setAppPayMode('subscribe')
    // 要強制測試免費版過期頁面，把這邊打開
    // setAppPayMode('free')
    // setTrialDayLeft(0)
    // 要強制測試免費版，還在試用期，把這邊打開
    // setAppPayMode('free')
    // setTrialDayLeft(7)
  }

  useEffect(() => {
    getAppSubscriptions()
    // 220231025 發現從[了解方案]頁面按瀏覽器上一頁回到主頁時，沒有重新載入(會導致如果 user 取消訂閱後，直接按瀏覽器上一頁回來，還是顯示[付費版]畫面，重新整理才會更新畫面)
    // 所以這邊加上[如果瀏覽器按上一頁]時，觸發重新整理頁面的機制
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload()
      }
    }
    window.addEventListener('pageshow', handlePageShow)

    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  }, [])

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  )
  const handleChange = useCallback(() => setActive(!active), [active])
  const handleChange2 = useCallback(() => setActive2(!active2), [active2])

  const handleViewHelp = () => {
    setActive(!active)
  }

  const handleViewPrivacy = () => {
    setActive2(!active2)
  }

  const tabs = [
    {
      id: 'main-page-1',
      content: t('home')
    },
    {
      id: 'edit-info-1',
      content: `1.${t('basic')}`
    },
    {
      id: 'all-order-1',
      content: `2.${t('sync_products')}`
    },
    {
      id: 'all-customers-1',
      content: `3.${t('sync_customers')}`
    },
    {
      id: 'run-orca-analystic',
      content: `4.${t('execute')}`
    },
    {
      id: 'download-report-1',
      content: t('operation')
    }
  ]

  const orcaXAidaTab = [
    {
      id: 'main-page-1',
      content: t('home')
    },
    {
      id: 'edit-info-1',
      content: `1.${t('basic')}`
    },
    {
      id: 'all-order-1',
      content: `2.${t('sync_products')}`
    },
    {
      id: 'all-customers-1',
      content: `3.${t('sync_customers')}`
    },
    {
      id: 'run-orca-analystic',
      content: `4.${t('execute')}`
    },
    {
      id: 'aida-settings',
      content: t('aida_recommended')
    },
    {
      id: 'download-report-1',
      content: t('operation')
    }
  ]

  return (
    <Frame>
      <Page>
        <Layout>
          <HelpModal
            active={active}
            setActive={setActive}
            handleChange={handleChange}
          />
          <PrivacyModal
            active={active2}
            setActive={setActive2}
            handleChange={handleChange2}
          />
          <Layout.Section>
            <LegacyCard>
              {trialDayLeft === -1 &&
                  <LegacyCard.Section>
                    <SkeletonBodyText lines={15} />
                  </LegacyCard.Section>}
              {!isOrcaXAida && trialDayLeft !== -1 &&
                <div>
                  <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                    {(selected === 0) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page0 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} isOrcaXAida={isOrcaXAida} /></>}
                    {(selected === 1) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page1 handleTabChange={handleTabChange} /></>}
                    {(selected === 2) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page2 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 3) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page3 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 4) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page4 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 5) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page6 appPayMode={appPayMode} trialDayLeft={trialDayLeft} /></>}
                  </Tabs>
                </div>}
              {isOrcaXAida && trialDayLeft !== -1 &&
                <div>
                  <Tabs tabs={orcaXAidaTab} selected={selected} onSelect={handleTabChange} fitted>
                    {(selected === 0) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page0 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} isOrcaXAida={isOrcaXAida}/></>}
                    {(selected === 1) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page1 handleTabChange={handleTabChange} /></>}
                    {(selected === 2) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page2 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 3) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page3 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 4) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page4 handleTabChange={handleTabChange} isPaid={appPayMode === 'subscribe'} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 5) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><PageAida handleTabChange={handleTabChange} appPayMode={appPayMode} trialDayLeft={trialDayLeft} /></>}
                    {(selected === 6) && <><CTABanner appPayMode={appPayMode} trialDayLeft={trialDayLeft}/><LanguageSwitcher/><Page6 appPayMode={appPayMode} trialDayLeft={trialDayLeft} /></>}
                  </Tabs>
                </div>}
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <FooterHelp gap='20'>
              <ButtonGroup>
                <Button onClick={handleViewPrivacy}>{t('terms')}</Button>
                <Button onClick={handleViewHelp}>{t('help')}</Button>
              </ButtonGroup>
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}
