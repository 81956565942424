import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Select } from 'antd'

const { Option } = Select

function getLastSixMonths () {
  const months = []
  const currentDate = new Date()
  for (let i = 0; i < 6; i++) {
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    console.log('month', month)
    months.push(`${year}${month}`)
    currentDate.setDate(1)
    currentDate.setMonth(currentDate.getMonth() - 1)
  }
  return months
}

const monthOptions = getLastSixMonths()

const ShopSelection = (props) => {
  const { shopList } = props
  const [options, setOptions] = useState(shopList)
  const handleOnSearch = (value) => {
    setOptions(shopList.filter(shop => shop.shopname.toLowerCase().includes(value.toLowerCase())))
  }
  return (<Select
      showSearch
      filterOption={false}
      onSearch={handleOnSearch}
      {...props}
    >
      {options.map((shop) => (<Option value={shop.shopname} key={shop.shopname}>{shop.shopname}</Option>))}
    </Select>
  )
}

ShopSelection.propTypes = {
  shopList: PropTypes.array.isRequired
}

const UploadToOrcaModal = ({ active, setActive, shopList, handleOnUploadToOrca }) => {
  const [form] = Form.useForm()

  const handleOnSubmit = () => {
    form.validateFields().then(() => {
      handleOnUploadToOrca(form.getFieldsValue())
      setActive(false)
    }).catch(error => console.log(error))
  }

  return (
    <Modal
      title={'同步資料至 ORCA'}
      open={active}
      onOk={handleOnSubmit}
      onCancel={() => setActive(false)}
      centered
      width="50%"
      okText="Submit"
      okButtonProps={{ style: { background: '#1890ff', borderColor: '#1890ff' } }}
    >
      <Form form={form}>
        <Form.Item
          label="商店名稱"
          name="shop"
          rules={[
            {
              required: true
            }
          ]}
        >
          <ShopSelection shopList={shopList} />
        </Form.Item>
        <Form.Item
          label="同步月份"
          name="month"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            {monthOptions.map((m) => (<Option value={m} key={m}>{m}</Option>))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

UploadToOrcaModal.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  shopList: PropTypes.array.isRequired,
  handleOnUploadToOrca: PropTypes.func.isRequired
}

export default UploadToOrcaModal
