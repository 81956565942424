import React, { useState, useEffect } from 'react'
import { adminInstance } from '../../helper'
import { Input, Table, Tag } from 'antd'
import Layout from '../../components/Layout/admin/Layout'

const { Column } = Table

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra)
}

const TaskPage = () => {
  const [tasks, setTasks] = useState([])
  const [filter, setFilter] = useState('')

  const fetchTasks = async () => {
    try {
      const res = await adminInstance.get('/tasks?per_page=200')
      setTasks(res.data.data)
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const filteredTasks = tasks.filter(task => task.shopname.toLowerCase().includes(filter.toLowerCase()))

  useEffect(() => {
    fetchTasks()
  }, [])

  return (
    <Layout>
        <Input style={{ marginBottom: '15px' }} placeholder="搜尋商店名稱" value={filter} onChange={(e) => setFilter(e.target.value)} />
        <Table dataSource={filteredTasks} onChange={onChange}>
          <Column title="商店名稱" dataIndex="shopname" key="shopname" />
          <Column title="工作項目" dataIndex="title" key="title" />
          <Column title="狀態" key="status"
            render={(_, task) => {
              let color = 'geekblue'
              switch (task.status) {
                case 'success':
                  color = 'green'
                  break
                case 'fail':
                  color = 'volcano'
                  break
                default:
                  color = 'geekblue'
              }
              return <Tag color={color}>{task.status}</Tag>
            }}
            filters={[
              { text: 'success', value: 'success' },
              { text: 'fail', value: 'fail' },
              { text: 'processing', value: 'processing' }
            ]}
            onFilter={(value, task) => task.status === value} />
          <Column title="錯誤訊息" key="errorMsg" render={(_, task) => (<>
            <span>{task.errorMsg}</span>
          </>)} />
          <Column title="創建時間" dataIndex="createdAt" key="createdAt" />
        </Table>
      </Layout>
  )
}

export default TaskPage
