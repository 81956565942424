import React from 'react'
import { useTranslation } from 'react-i18next'

const ErrorPage = () => {
  const { t } = useTranslation('errorPage')

  return (
    <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '500px', height: '300px', border: 'red 10px solid', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ fontSize: '100px' }}>{t('error')}</h1>
      </div>
    </div>
  )
}

export default ErrorPage
