import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { adminInstance } from '../../helper'
import { Modal, Form, FormLayout, TextField, Select } from '@shopify/polaris'

const ShopInfoEditModal = ({ open, onClose, shopId }) => {
  const [shop, setShop] = useState({ shopname: '' })

  const fetchShopData = async () => {
    try {
      const res = await adminInstance.get(
        `/shop/${shopId}`
      )
      setShop(res.data.data)
    } catch (error) {
      console.log(error.response.data)
    }
  }
  const updateShopInfo = async () => {
    const { id, payMode, expireDate } = shop
    try {
      await adminInstance.patch(
        `/shop/${id}`,
        { payMode, expireDate }
      )
      onClose()
    } catch (error) {
      console.log(error.response.data)
    }
  }

  useEffect(() => {
    fetchShopData()
  }, [shopId])

  const subscriptionOptions = [
    { label: '試用版', value: 'trial' },
    { label: '免費版', value: 'free' },
    { label: '付費版', value: 'subscribe' }
  ]

  return (
    <>
    {shop && (
    <Modal
      open={open}
      onClose={onClose}
      title='編輯商店資訊'
      primaryAction={{
        content: '更新',
        onAction: updateShopInfo
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
          <TextField label="商店名稱" value={shop.shopname} disabled />
          <Select
            label='版本'
            options={subscriptionOptions}
            value={shop.payMode}
            onChange={(value) => setShop({ ...shop, payMode: value })}
          />
          <label>到期日 (切換至免費版可留空)</label>
          <input
            type="date"
            className="border-gray-300 border rounded-md p-2 w-full"
            onChange={(e) => {
              const selectDate = e.target.value
              if (selectDate) {
                setShop({ ...shop, expireDate: selectDate })
              } else {
                setShop({ ...shop, expireDate: null })
              }
            }}
          />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>)}
    </>
  )
}

ShopInfoEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}

export default ShopInfoEditModal
