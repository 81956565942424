import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, message } from 'antd'
import { authInstance } from '../helper'
import { Spinner } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const { Column } = Table

const Select = ({ value, options, onChange }) => {
  return (
    <select
      className='border border-gray-300 rounded-md w-28'
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
}

const EditSegmentsModal = ({ active, setActive, refreshProductData }) => {
  const shop = String(window.localStorage.getItem('shop'))
  const isShopline = shop.includes('shopline')
  const [products, setProducts] = useState({})
  const [waiting, setWaiting] = useState(false)
  const { t } = useTranslation('editSegmentsModal')

  const getProducts = async () => {
    setWaiting(true)
    try {
      const res = await authInstance.get('/products/')
      const products = res.data
      setProducts(products)
    } catch (error) {
      message.error(JSON.stringify(error.response.data))
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  const onSave = async () => {
    await authInstance.post('/products/', products)
    setActive(false)
    await refreshProductData()
  }

  return (
    <Modal
      title={t('edit_category')}
      open={active}
      onOk={onSave}
      onCancel={() => setActive(false)}
      centered
      width="auto"
      okText={t('save_button')}
      cancelText={t('cancel_button')}
      onOkType="default"
      okButtonProps={{ style: { background: '#1890ff', borderColor: '#1890ff' } }}
    >
      <div className='max-h-[500px] overflow-y-auto mt-12 mb-8 w-full flex justify-center'>
        {waiting
          ? <Spinner size="large" color="teal" />
          : (<>
            <Table dataSource={Object.keys(products).map(pId => products[pId])}>
              <Column title={t('prod_name')} dataIndex="title" key="title" />
              <Column title={t('prod_id')} dataIndex="id" key="id" />
              <Column title={isShopline ? t('primary_category') : t('prod_series')} dataIndex="item_copy" key="item_copy" render={(_, product) => {
                return product.item_copy.options.length === 1
                  ? product.item_copy.value
                  : <Select
                      value={product.item_copy.value}
                      options={product.item_copy.options.map((option) => ({ label: option, value: option }))}
                      onChange={(value) => setProducts({ ...products, [product.id]: { ...products[product.id], item_copy: { ...products[product.id].item_copy, value } } })}
                  />
              }} />
              <Column title={isShopline ? t('sub_category_1') : t('main_category')} dataIndex="segment1" key="segment1" render={(_, product) => {
                return product.segment1.options.length === 1
                  ? product.segment1.value
                  : <Select
                      value={product.segment1.value}
                      options={product.segment1.options.map((option) => ({ label: option, value: option }))}
                      onChange={(value) => setProducts({ ...products, [product.id]: { ...products[product.id], segment1: { ...products[product.id].segment1, value } } })}
                  />
              }} />
              <Column title={isShopline ? t('sub_category_2') : t('sub_category')} dataIndex="segment2" key="segment2" render={(_, product) => {
                return product.segment2.options.length === 1
                  ? product.segment2.value
                  : <Select
                      value={product.segment2.value}
                      options={product.segment2.options.map((option) => ({ label: option, value: option }))}
                      onChange={(value) => setProducts({ ...products, [product.id]: { ...products[product.id], segment2: { ...products[product.id].segment2, value } } })}
                  />
              }} />
              <Column title={isShopline ? t('sub_category_3') : t('small_category')} dataIndex="segment3" key="segment3" render={(_, product) => {
                return product.segment3.options.length === 1
                  ? product.segment3.value
                  : <Select
                      value={product.segment3.value}
                      options={product.segment3.options.map((option) => ({ label: option, value: option }))}
                      onChange={(value) => setProducts({ ...products, [product.id]: { ...products[product.id], segment3: { ...products[product.id].segment3, value } } })}
                  />
              }} />
            </Table>
          </>)}
      </div>
    </Modal>
  )
}

Select.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired
}

EditSegmentsModal.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  refreshProductData: PropTypes.func.isRequired
}

export default EditSegmentsModal
