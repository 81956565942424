import axios from 'axios'
import { BACKEND_URL } from './config'

const instance = axios.create({
  baseURL: `${BACKEND_URL}/api`
})

const authInstance = axios.create({
  baseURL: `${BACKEND_URL}/api`,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
  }
})

const adminInstance = axios.create({
  baseURL: `${BACKEND_URL}/api/admin`,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('adminToken')}`
  }
})

authInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 422) {
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

adminInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 422) {
    window.location.href = '/admin/login'
  }
  return Promise.reject(error)
})

const getOrcaHistory = async () => {
  try {
    const res = await authInstance.get('/get_orca_history', {})
    return res
  } catch (error) {
    console.log(error)
  }
}

const showTime = (inTimeStr) => {
  const originalDate = new Date(inTimeStr)
  // 轉換為台灣時區
  const taiwanDateTimeString = originalDate.toLocaleString('en-US', {
    timeZone: 'Asia/Taipei',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  return taiwanDateTimeString
  // return inTimeStr.replace('T', ' ').replace('Z', '').split('.')[0]
}

export { BACKEND_URL, instance, authInstance, adminInstance, getOrcaHistory, showTime }
