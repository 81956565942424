import { React } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, FormLayout } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const HelpModal = (props) => {
  const { active, handleChange } = props
  const { t } = useTranslation('helpModal')

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={t('seek_help')}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
          <div className='text-black m-5'>
          <h1 className='text-3xl font-bold mb-2'>{t('seek_help')}</h1>
          <p>{t('thank_you')}</p>
          <br />
          <p>{t('email_subject')}</p>
          <br />
          <p>{'caesarliu@wavenet.com.tw'}</p>
          </div>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

HelpModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default HelpModal
