import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Button, IndexTable, Spinner, Text } from '@shopify/polaris'
import { PageDownIcon } from '@shopify/polaris-icons'
import { authInstance } from '../helper'
import PropTypes from 'prop-types'
import OrcaHistoryTable from '../components/OrcaHistoryTable'
import { useTranslation } from 'react-i18next'

function Page6 ({ appPayMode, trialDayLeft }) {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('page6')

  const isPageActive = (appPayMode === 'subscribe') || (trialDayLeft > 0)

  const fetchTasks = () => {
    setLoading(true)
    authInstance.get('/tasks').then(res => {
      setTasks(res.data)
      setLoading(false)
    })
  }

  const handleRefresh = useCallback(() => {
    fetchTasks()
  }, [])

  useEffect(() => {
    fetchTasks()
  }, [])

  const statusType = Object.freeze({
    pending: 'attention',
    processing: 'warning',
    success: 'success',
    fail: 'critical'
  })

  const rowMarkup = tasks.map(
    ({ createdAt, id, title, status, downloadUrl, errorMsg }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {title}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge tone={statusType[status]}>
            {status}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {(downloadUrl) &&
            <Button icon={PageDownIcon} url={downloadUrl} color="base" disabled={!isPageActive}/>
          }
          </IndexTable.Cell>
        <IndexTable.Cell>{errorMsg}</IndexTable.Cell>
      </IndexTable.Row>
    )
  )

  return (
    <div className='px-16 py-4'>
      <div className='flex justify-end mb-3'>
        <Button onClick={handleRefresh} disabled={!isPageActive} tone="success" variant="primary">{t('refresh')}</Button>
      </div>
      <h2 className='font-bold text-lg'>{t('operation_records')}</h2>
      <div className='mb-8'>
        {(loading) && (<div className='flex justify-center items-center h-[200px]'>
          <Spinner />
        </div>)}
        {(!loading) && (tasks.length === 0) && (<div className='flex justify-center'>
          <p className='text-lg font-bold'>{t('no_items')}</p>
        </div>)}
        {(!loading) && (tasks.length > 0) && (
          <div className='bg-gray-100 border-2 border-solid'>
            <IndexTable
              itemCount={tasks.length}
              headings={[
                { title: t('creation_time') },
                { title: t('work_item') },
                { title: t('status') },
                { title: t('download_link') },
                { title: t('error_message') }
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          </div>
        )}
      </div>
      <h2 className='font-bold text-lg'>{t('data_analysis_history')}</h2>
      <OrcaHistoryTable />
    </div>
  )
}

Page6.propTypes = {
  appPayMode: PropTypes.string,
  trialDayLeft: PropTypes.number
}

export default Page6
