import { React, useState, useCallback, useEffect } from 'react'
import { Text, Form, FormLayout, TextField, Button, LegacyCard, Banner, Grid, InlineStack } from '@shopify/polaris'
import { authInstance } from '../helper'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import Page1Modal from '../components/Page1Modal'

const Page1 = ({ handleTabChange }) => {
  const [domain, setDomain] = useState('')
  const [cName, setCName] = useState('')
  const [cid, setCId] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [errorMessages, setErrorMessages] = useState({
    cName: '',
    cid: '',
    email: '',
    phone: ''
  })

  const [domainSrc, setDomainSrc] = useState('')
  const [cNameSrc, setCNameSrc] = useState('')
  const [cidSrc, setCIdSrc] = useState('')
  const [emailSrc, setEmailSrc] = useState('')
  const [phoneSrc, setPhoneSrc] = useState('')

  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [message, setMessage] = useState('')

  const [active, setActive] = useState(false)
  const { t } = useTranslation('page1')

  // const [edmEmail, setEDMEmail] = useState('')
  // const [edmContext, setEDMContext] = useState('')

  // const [lineID, setLineID] = useState('')
  // const [lineName, setLineName] = useState('')
  // const [lineToken, setLineToken] = useState('')
  // const [lineSecret, setLineSecret] = useState('')
  const handleChange = useCallback(() => setActive(!active), [active])

  const getShopInfo = async () => {
    const res = await authInstance.get('/get_shop_info')
    if (res.status === 200) {
      const data = res.data
      setDomain(data.shopname)
      setCName(data.company_name)
      setCId(data.company_id_number)
      setEmail(data.email)
      setPhone(data.phone)

      setDomainSrc(data.shopname)
      setCNameSrc(data.company_name)
      setCIdSrc(data.company_id_number)
      setEmailSrc(data.email)
      setPhoneSrc(data.phone)
      // setorcaNames(data.oca_names)
    }
  }

  useEffect(() => {
    getShopInfo()
  }, [])

  const validateFields = () => {
    let isValid = true
    const newErrorMessages = {
      cName: '',
      cid: '',
      email: '',
      phone: ''
    }
    if (!cName) {
      newErrorMessages.cName = t('company_name_required')
      isValid = false
    }
    if (!cid) {
      newErrorMessages.cid = t('tax_id_number_required')
      isValid = false
    }
    // 可能有其他國家的公司使用，所以不檢查統編
    // else if (!/^\d{8}$/.test(cid)) {
    //   newErrorMessages.cid = t('tax_id_number_incorrect')
    //   isValid = false
    // }
    if (!email) {
      newErrorMessages.email = t('email_required')
      isValid = false
    }
    if (!phone) {
      newErrorMessages.phone = t('email_required')
      isValid = false
    }
    // 可能有其他國家的公司使用，所以不檢查手機
    // else if (!/^09\d{8}$/.test(phone)) {
    //   newErrorMessages.phone = t('mobile_incorrect')
    //   isValid = false
    // }
    setErrorMessages(newErrorMessages)
    console.log('isValid:', isValid)
    return isValid
  }

  const handleSubmit = async () => {
    if (!validateFields()) {
      return
    }
    const orcaLanguage = localStorage.getItem('orcaLanguage')
    let formData = new FormData()
    formData.append('cName', cName)
    formData.append('cid', cid)
    formData.append('email', email)
    formData.append('domain', domain)
    formData.append('phone', phone)
    // BM存：comp_default_language: 'zh-tw'(用小寫), 'en', 'jp'
    if (orcaLanguage) {
      formData.append('orcaLanguage', orcaLanguage)
    }
    // formData.append('edmEmail', edmEmail)
    // formData.append('edmContext', edmContext)
    // formData.append('lineID', lineID)
    // formData.append('lineName', lineName)
    // formData.append('lineToken', lineToken)
    // formData.append('lineSecret', lineSecret)
    await authInstance.post('/set_shop_info', formData)
    formData = new FormData()
    formData.append('cName', cName)
    formData.append('cid', cid)
    formData.append('email', email)
    formData.append('domain', domain)
    formData.append('phone', phone)
    if (orcaLanguage) {
      formData.append('orcaLanguage', orcaLanguage)
    }
    await authInstance.post('/send_shop_info_to_orca_bm', formData)

    setDomainSrc(domain)
    setCNameSrc(cName)
    setCIdSrc(cid)
    setEmailSrc(email)
    setPhoneSrc(phone)

    setOpenSuccessBanner(true)
    setMessage(t('data_saved'))
    localStorage.removeItem('orcaLanguage')
  }

  const handleNextPage = async () => {
    if (domain === domainSrc &&
    cName === cNameSrc &&
    cid === cidSrc &&
    email === emailSrc &&
    phone === phoneSrc) {
      handleTabChange(2)
    } else {
      setActive(!active)
    }
  }

  const handleCNameChange = (value) => {
    setCName(value)
    setErrorMessages({ ...errorMessages, cName: '' })
  }

  const handleCIdChange = (value) => {
    setCId(value)
    setErrorMessages({ ...errorMessages, cid: '' })
  }

  const handleEmailChange = (value) => {
    setEmail(value)
    setErrorMessages({ ...errorMessages, email: '' })
  }

  const handlePhoneChange = (value) => {
    setPhone(value)
    setErrorMessages({ ...errorMessages, phone: '' })
  }

  const handleDomainChange = (value) => {
    setDomain(value)
  }
  // const handleEDMEmailChange = useCallback((value) => setEDMEmail(value), [])
  // const handleEDMContextChange = useCallback((value) => setEDMContext(value), [])

  // const handleLineIDChange = useCallback((value) => setLineID(value), [])
  // const handleLineNameChange = useCallback((value) => setLineName(value), [])
  // const handleLineTokenChange = useCallback((value) => setLineToken(value), [])
  // const handleLineSecretChange = useCallback((value) => setLineSecret(value), [])

  return (
    <div>
        <Page1Modal
          active={active}
          setActive={setActive}
          handleChange={handleChange}
          handleTabChange={handleTabChange}
        />
        <div className='mb-4'>
          {openSuccessBanner && <Banner
            title= {message}
            tone='success'
            onDismiss={() => {
              setOpenSuccessBanner(false)
            }}
          />}
        </div>
      <LegacyCard.Section>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <div style={{ paddingBottom: '10px' }}>
              <Text variant="headingXl">{t('company_info')}</Text>
            </div>
            <Form>
              <FormLayout>
                <TextField
                  value={cName}
                  onChange={handleCNameChange}
                  label={t('company_name')}
                  type="cname"
                  autoComplete="cname"
                  placeholder={t('enter_company_name')}
                  error={errorMessages.cName}
                />
                <TextField
                  value={cid}
                  onChange={handleCIdChange}
                  label={t('tax_id_number')}
                  type="cid"
                  autoComplete="cid"
                  placeholder={t('enter_tax_id_number')}
                  error={errorMessages.cid}
                />
                <TextField disabled
                  value={domain}
                  onChange={handleDomainChange}
                  label={t('domain')}
                  type="domain"
                  autoComplete="domain"
                />
              </FormLayout>
            </Form>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <div style={{ paddingBottom: '10px' }}>
              <Text variant="headingXl">{t('orca_info')}</Text>
            </div>
            <Form>
              <FormLayout>
                <TextField
                  value={email}
                  onChange={handleEmailChange}
                  label={t('admin_email')}
                  type="email"
                  autoComplete="email"
                  placeholder={t('enter_admin_email')}
                  error={errorMessages.email}
                />
                <TextField
                  value={phone}
                  onChange={handlePhoneChange}
                  label={t('mobile_phone')}
                  type="phone"
                  autoComplete="phone"
                  placeholder={`${t('mobil_phone_format')}: 0912345678`}
                  error={errorMessages.phone}
                />
              </FormLayout>
            </Form>
          </Grid.Cell>
        </Grid>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <InlineStack align='center'>
          <Button onClick={handleSubmit}>{t('save_data')}</Button>
        </InlineStack>
        <InlineStack align="end">
          <Button onClick={handleNextPage}>{t('next_step')}</Button>
        </InlineStack>
      </LegacyCard.Section>
    </div>
  )
}
Page1.propTypes = {
  handleTabChange: PropTypes.func.isRequired
}
export default Page1
