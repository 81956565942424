import React, { useState, useEffect } from 'react'
import { authInstance } from '../helper'

const History = () => {
  const [history, setHistory] = useState('')

  const handleOrcaHistory = async () => {
    const res = await authInstance.get('/get_orca_history', {})
    setHistory(res.data.data)
  }

  useEffect(() => {
    handleOrcaHistory()
  }, [])

  return (
    <div className='text-black m-5'>
      {history}
    </div>
  )
}

export default History
