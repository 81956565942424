import React from 'react'
import { useTranslation } from 'react-i18next'

const Help = () => {
  const { t } = useTranslation('help')

  return (
    <div className='text-black m-5'>
      <h1 className='text-3xl font-bold mb-2'>{t('seek_help')}</h1>
      <p>{t('thank_you')}</p>
      <br />
      <p>{t('email_subject')}</p>
      <br />
      <p>{'caesarliu@wavenet.com.tw'}</p>
    </div>
  )
}

export default Help
