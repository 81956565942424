import React, { useState, useEffect } from 'react'
import { Button, LegacyCard, Text, DataTable, SkeletonBodyText, BlockStack, InlineStack, Toast } from '@shopify/polaris'
import DropZoneComponents from '../components/DropZone'
import { authInstance } from '../helper'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

const Page3 = ({ handleTabChange, isPaid, trialDayLeft }) => {
  const [crmFile, setCrmFile] = useState('')
  // const [itemFile, setItemFile] = useState()
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [message, setMessage] = useState('')
  const { t } = useTranslation('page3')

  const [rowData, setRowData] = useState(null)
  const [projectState, setProjectState] = useState(-1)
  const colTitle = [t('customer_number'), t('marketing_consent'), t('gender'), t('email'), t('phone'), t('line_ID')]
  const colType = ['text', 'text', 'text', 'text', 'text', 'text']

  const isPageActive = isPaid || (trialDayLeft > 0)

  /*
  const uploadToBucket = async () => {
    await authInstance.get('/reports/upload-to-orca')
    setOpenSuccessBanner(true)
  }
  */

  const uploadFiles = async () => {
    if (crmFile === '') {
      setMessage(t('select_file'))
      setOpenSuccessBanner(true)
      return
    }
    const formData = new FormData()
    formData.append('crmFile', crmFile)
    setMessage(t('task_creating'))
    setOpenSuccessBanner(true)
    await authInstance.post('/reports/upload-to-shopify', formData)
    setMessage(t('task_scheduled'))
    setOpenSuccessBanner(true)
  }

  const downloadFiles = async () => {
    setMessage(t('task_creating'))
    setOpenSuccessBanner(true)
    await authInstance.get('/reports/download', {
      params: {
        types: 'crmProfile'
      }
    })
    setMessage(t('preparing'))
    setOpenSuccessBanner(true)
  }

  const anyncForUseEffect = async () => {
    let res = await authInstance.get('/get_shop_info')
    /*
    0 = 基本資料未設定
    1 = 基本資料已設定
    2 = 自動拋轉資料到 ORCA 進行中
    3 = 自動拋轉資料到 ORCA 完成
    4 = 已送出分析請求但未完成
    5 = 分析完成
    */
    let tempState = 0
    if (res.data.orca_state === null) {
      setProjectState(0)
      return
    } else {
      const orcaState = res.data.orca_state
      if (orcaState === 0) {
        setProjectState(0)
        return
      } else if (orcaState === 1) {
        tempState = 1
      } else if (orcaState === 2) {
        tempState = 2
      } else if (orcaState === 3) {
        tempState = 2
      } else if (orcaState === 4) {
        tempState = 2
      } else if (orcaState === 5) {
        tempState = 2
      }
    }
    if (isPaid === false) {
      if (tempState === 2) {
        tempState = 1
      }
    }
    if (tempState > 0) {
      res = await authInstance.get('/preview_crms')
      setRowData(res.data)
    }
    setProjectState(tempState)
  }

  const autoUploadToOrcaHandler = async () => {
    await authInstance.get('/reports/auto-upload-to-orca')
  }

  const handleNextStep = async () => {
    if (projectState === 1 && isPageActive === true) {
      await autoUploadToOrcaHandler()
    }
    handleTabChange(4)
  }

  useEffect(() => {
    anyncForUseEffect()
  }, [])

  return (
    <div>
      {(projectState === -1) &&
        <LegacyCard.Section>
          <SkeletonBodyText lines={10}/>
        </LegacyCard.Section>
      }
      {(projectState === 0) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="center">
            <Text variant="headingSm">
              {t('basic_info_missing')}
            </Text>
            <Button onClick={() => handleTabChange(1)}>{t('fill_in_basic_information')}</Button>
          </BlockStack>
        </LegacyCard.Section>
      }
      {(projectState >= 1) && <div>
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="start">
            <Text as="h1" variant="headingXl">
              {t('confirm_customer_data')}
            </Text>
            <BlockStack gap="2">
              <Text as="p" variant="bodyLg">
                {t('orca_consent')}
              </Text>
            </BlockStack>
          </BlockStack>
          <InlineStack align="end">
            <Button onClick={downloadFiles} disabled={!isPageActive}>{t('download_customer_data')}</Button>
          </InlineStack>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <DataTable
            columnContentTypes={colType}
            headings={colTitle}
            rows={rowData}
            increasedTableDensity={true}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          <DropZoneComponents title={t('dropzone_title')} file={crmFile} setFile={setCrmFile} isDisabled={!isPageActive} />
        </LegacyCard.Section>
        <LegacyCard.Section>
          <InlineStack align='center'>
            <Button onClick={uploadFiles} disabled={!isPageActive} >{t('save')}</Button>
          </InlineStack>
          <InlineStack align="end">
            <Button onClick={handleNextStep}>{t('next_step')}</Button>
          </InlineStack>
        </LegacyCard.Section>
        {openSuccessBanner &&
          <Toast
            content={message} duration={8000}
            onDismiss={() => { setOpenSuccessBanner(false) }} />}
        </div>
      }
    </div>
  )
}

Page3.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
  trialDayLeft: PropTypes.number.isRequired
}
export default Page3
