import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Page, Toast, Frame } from '@shopify/polaris'
import { Table, Button as AntdButton, Flex, Avatar } from 'antd'
import Logo from '../assets/orca-logo.png'
import { authInstance } from '../helper'
import { getAntdTableDataSource } from '../assets/pricing-content'
import LanguageSwitcher from '../components/LanguageSwitcher'
import { useTranslation } from 'react-i18next'

const Title = ({ title }) => {
  return (<div className="bg-gray-300 h-14 rounded flex justify-center items-center mt-4 mb-8">
    <div className="w-11/12 flex justify-center items-center">
      <Avatar shape="square" size='large' src={Logo} />
      <h1 className="font-bold text-lg">{title}</h1>
    </div>
    <div>
      <LanguageSwitcher/>
    </div>
  </div>)
}

Title.propTypes = {
  title: propTypes.string.isRequired
}

const Footer = () => {
  const { t } = useTranslation('pricing')
  return (<div className="h-8 flex justify-center items-center font-medium">
    <a href="https://www.wavenet.com.tw/about-wavenet/" target='_blank' rel="noreferrer">{t('wavenet')}</a>
    </div>)
}

const Pricing = () => {
  const [appPayMode, setAppPayMode] = useState('free')
  const [planName, setPlanName] = useState('免費版')
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [platform, setPlatform] = useState('')
  const { t } = useTranslation(['pricing', 'pricingContent'])

  const handleSubscriptionShopify = async (paymode) => {
    const res = await authInstance.get('/create_shopify_subscription', { params: { mode: paymode } }) // 'month', 'year'
    // console.log(res)
    window.location.replace(res.data)
  }

  const getAppSubscriptions = async () => {
    const res = await authInstance.get('/get_app_subscriptions', {})
    if (res.data.payMode) setAppPayMode(res.data.payMode)
    if (res.data.payModeName) setPlanName(res.data.payModeName)
    if (res.data.platform) setPlatform(res.data.platform)
  }

  const handleCencalSubscription = async () => {
    const res = await authInstance.get('/cancel_app_subscriptions')
    console.log(res)
    getAppSubscriptions()
    setOpenSuccessBanner(true)
  }

  useEffect(() => {
    getAppSubscriptions()
  }, [])

  const handleSubscriptionForm = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeaOZCb8vckO3lsyEH30eZiEo_54VZl1LrTtNZRA1kM2z3zWQ/viewform', '_blank')
  }

  const antdTableColumns = [
    {
      title: t('pricing:plan'),
      dataIndex: 'function',
      key: 'function',
      align: 'center'
      // render: (text) => <div style={{ whiteSpace: 'pre-line' }}>{text}</div>
    },
    {
      title: platform === 'shopify' ? t('pricing:free') : t('pricing:trial'),
      dataIndex: 'trial',
      key: 'trial',
      align: 'center'
    },
    {
      title: t('pricing:paid'),
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: (text, record, index) => {
        if (record.key === 'subscription-button') {
          if (platform === 'shopify') {
            return <Flex gap='large' justify='center'>
                <AntdButton size='large' onClick={() => handleSubscriptionShopify('month')}>{t('pricing:monthly')}</AntdButton>
                <AntdButton size='large' style={{ background: '#1677ff', color: '#fff' }} onClick={() => handleSubscriptionShopify('year')}>{t('pricing:annually')}</AntdButton>
              </Flex>
          }
          // <Button> type="primary" 失效
          return <AntdButton style={{ background: '#1677ff', color: '#fff' }} onClick={handleSubscriptionForm}>{t('pricing:subscribe')}</AntdButton>
        }
        if (record.key === 'un-subscription-button') {
          return <Flex gap='small' align='center' vertical>
            <div>{text}</div>
            <AntdButton onClick={handleCencalSubscription}>{t('pricing:unsubscribe')}</AntdButton>
          </Flex>
        }
        return <div style={{ whiteSpace: 'pre-line' }}>{text}</div>
      }
    }
  ]

  return (
    <Frame>
      <Page>
        <Table
          title={() => <Title title={t('pricing:title')} />}
          footer={() => <Footer />}
          columns={antdTableColumns}
          dataSource={getAntdTableDataSource(platform, appPayMode, planName, t)}
          bordered
          pagination={false}
          rowClassName={(record, index) => {
            if (record.function.includes('數據分析儀表板') || record.function.includes('行銷自動化工具')) {
              return 'bg-slate-100 font-semibold whitespace-pre-line'
            }
            if (record.key === 'aida') {
              return 'font-semibold'
            }
            return 'whitespace-pre-line'
          }}
        />
        {openSuccessBanner &&
          <Toast
          content={t('pricing:canceled')} duration={5000}
          onDismiss={() => { setOpenSuccessBanner(false) }}
          />
        }
      </Page>
    </Frame>
  )
}

export default Pricing
