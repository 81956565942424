import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { LegacyCard, DropZone, LegacyStack, Thumbnail, Text } from '@shopify/polaris'
import { NoteIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

function DropZoneComponent ({ title, file, setFile, isDisabled }) {
  const { t } = useTranslation('dropZone')

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  )

  const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

  const fileUpload = !file && <DropZone.FileUpload actionTitle={t('upload_file')} actionHint={t('action_hint')}/>
  const uploadedFile = file && (
    <LegacyStack>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validFileTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteIcon
        }
      />
      <div>
        {file.name}{' '}
        <Text variant="bodySm" as="p">
          {file.size} bytes
        </Text>
      </div>
    </LegacyStack>
  )

  return (
    <LegacyCard title={title} sectioned>
      <DropZone allowMultiple={false} onDrop={handleDropZoneDrop} variableHeight disabled={isDisabled}>
        {uploadedFile}
        {fileUpload}
      </DropZone>
    </LegacyCard>
  )
}

DropZoneComponent.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.any.isRequired,
  setFile: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default DropZoneComponent
