import { React } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, FormLayout } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const PrivacyModal = (props) => {
  const { active, handleChange } = props
  const { t } = useTranslation('privacyModal')

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={t('terms_privacy')}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
          <div className='text-black m-5'>
            <h1 className='text-3xl font-bold mb-2'>{t('privacy_policy')}</h1>
            <p>{`${t('service_desc')}${t('read_statement')}`}</p>
            <br />
            <p>{t('service_provided')}</p>
            <br />
            <p>{t('update_statement')}</p>
            <br />
            <p>{t('confidentiality')}</p>
            <br />
            <p>{t('data_collection')}</p>
            <br />
            <p>{t('third_party_data')}</p>
            <br />
            <p>{t('no_warranty')}</p>
            <br />
            <p>{t('termination_rights')}</p>
            <br />
            <p>{t('intellectual_property')}</p>
            <br />
            <p>{t('damage_liability')}</p>
          </div>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

PrivacyModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default PrivacyModal
