import React from 'react'
import { useTranslation } from 'react-i18next'

const Privacy = () => {
  const { t } = useTranslation('privacy')

  return (
    <div className='text-black m-5'>
      <h1 className='text-3xl font-bold mb-2'>{t('privacy')}</h1>
      <p>{t('service_description')}</p>
      <br />
      <p>{t('service_as')}</p>
      <br />
      <p>{t('update_rights')}</p>
      <br />
      <p>{t('confidentiality')}</p>
      <br />
      <p>{t('privacy_policy')}</p>
      <br />
      <p>{t('third_party_data')}</p>
      <br />
      <p>{t('no_warranty')}</p>
      <br />
      <p>{t('service_termination')}</p>
      <br />
      <p>{t('ip_rights')}</p>
      <br />
      <p>{t('liability')}</p>
    </div>
  )
}

export default Privacy
