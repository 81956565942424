import React, { useState } from 'react'
import { LegacyCard, Form, TextField } from '@shopify/polaris'
import { ALLOWED_REDIRECTS, OAUTH_CALLBACK_URL, BACKEND_URL } from '../config'
import Logo from '../assets/orca-logo.png'
import { authInstance } from '../helper'
import { useTranslation } from 'react-i18next'

const redirect = OAUTH_CALLBACK_URL

const WooCommerceLoginPage = () => {
  const [shop, setShop] = useState('')
  const [consumerKey, setConsumerKey] = useState('')
  const [consumerSecret, setConsumerSecret] = useState('')
  const { t } = useTranslation('loginPage')

  console.log('ALLOWED_REDIRECTS in loginPage: ', ALLOWED_REDIRECTS)

  const login = async () => {
    console.log('login...')
    try {
      const res = await authInstance.get('/woo_commerce/login', {
        params: {
          shop_url: shop,
          consumer_key: consumerKey,
          consumer_secret: consumerSecret
        }
      })
      // console.log(res)
      window.localStorage.setItem('shop', res.data.shop)
      window.localStorage.setItem('accessToken', res.data.access_token)
      window.location.href = '/'
    } catch (error) {
      console.log(error)
    }
    console.log(redirect)
    console.log(BACKEND_URL)
    console.log(shop)
    console.log(consumerKey)
    console.log(consumerSecret)
  }

  return (
    <section className="h-screen flex items-center justify-center bg-slate-500">
      <div className='w-1/2'>
        <LegacyCard
          sectioned
          primaryFooterAction={{ content: t('login'), onClick: () => login() }}
        >
          <LegacyCard.Section>
            <p>WooCommerce登入頁面</p>
            <div className='flex justify-center items-center'>
              <img
                src={Logo}
                className="w-[300px]"
              />
            </div>
            <Form>
              <TextField
                value={shop}
                onChange={(value) => setShop(value)}
                label={'shop_url'}
                placeholder="Example: panmarket.asia"
                autoComplete="off"
              />
              <TextField
                value={consumerKey}
                onChange={(value) => setConsumerKey(value)}
                label={'consumer_key'}
                autoComplete="off"
              />
              <TextField
                value={consumerSecret}
                onChange={(value) => setConsumerSecret(value)}
                label={'consumer_secret'}
                autoComplete="off"
              />
            </Form>
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </section>
  )
}

export default WooCommerceLoginPage
