import React, { useEffect, useState } from 'react'
import { LegacyCard, Form, TextField, Page, Text, Button } from '@shopify/polaris'
import { ALLOWED_REDIRECTS, BACKEND_URL, OAUTH_CALLBACK_URL, DEV_MODE, SHOP, ACCESS_TOKEN } from '../config'
import Logo from '../assets/orca-logo.png'
import { useTranslation } from 'react-i18next'

const redirect = OAUTH_CALLBACK_URL

const LoginPage = () => {
  const [shop, setShop] = useState('')
  const [initShop, setInitShop] = useState('')
  const [loading, setLoding] = useState(false)
  const { t } = useTranslation('loginPage')

  console.log('ALLOWED_REDIRECTS in loginPage: ', ALLOWED_REDIRECTS)

  useEffect(() => {
    if (DEV_MODE === '2') { // 20240911 Leo: 測試 WooCommerce 我本機端用 REACT_APP_DEV_MODE=2
      window.location.href = '/woo_commerce_login'
    } else if (DEV_MODE) {
      window.localStorage.setItem('shop', SHOP)
      window.localStorage.setItem('accessToken', ACCESS_TOKEN)
      window.location.href = '/'
    } else {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const shopname = urlParams.get('shop')
      const merchantid = urlParams.get('merchant_id')
      if (merchantid) {
        console.log('if merchantid: ', merchantid)
        setShop(merchantid)
        // window.location.replace(`${BACKEND_URL}/api/shop/login?shop=${merchantid}&redirect=${redirect}`)
        const redirectUrl = `${BACKEND_URL}/api/shop/login?shop=${merchantid}&redirect=${redirect}`
        if (ALLOWED_REDIRECTS.includes(redirect)) {
          console.log('merchantid redirect allowed: ', redirect, 'redirectUrl: ', redirectUrl)
          window.location.replace(redirectUrl)
        } else {
          console.log('merchantid redirect not allowed: ', redirect, 'redirectUrl: ', redirectUrl)
        }
        setInitShop(initShop)
        setLoding(true)
      } else if (shopname) {
        console.log('else if shopname: ', shopname)
        setShop(shopname)
        // window.location.replace(`${BACKEND_URL}/api/shop/login?shop=${shopname}&redirect=${redirect}`)
        const redirectUrl = `${BACKEND_URL}/api/shop/login?shop=${shopname}&redirect=${redirect}`
        if (ALLOWED_REDIRECTS.includes(redirect)) {
          console.log('shopname redirect allowed: ', redirect, 'redirectUrl: ', redirectUrl)
          window.location.replace(redirectUrl)
        } else {
          console.log('shopname redirect not allowed: ', redirect, 'redirectUrl: ', redirectUrl)
        }
        setInitShop(initShop)
        setLoding(true)
      }
    }
  }, [])

  const handleChange = (value) => setShop(value)

  const handleWooCommerceLogin = () => {
    window.location.href = '/woo_commerce_login'
  }

  const login = () => {
    window.location.replace(`${BACKEND_URL}/api/shop/login?shop=${shop}&redirect=${redirect}`)
  }
  if (loading === true) {
    return (
      <Page>
        <Text variant="heading2xl">{t('loading')}</Text>
      </Page>
    )
  }

  if (initShop !== '') {
    return (
      <Page>
        <Text variant="heading2xl">{t('loading')}</Text>
      </Page>
    )
  } else {
    return (
      <section className="h-screen flex items-center justify-center bg-slate-500">
        <div className='w-1/2'>
          <LegacyCard
            sectioned
            primaryFooterAction={{ content: t('login'), onClick: () => login() }}
          >
            <Button onClick={handleWooCommerceLogin}>WooCommerce 登入請按此</Button>
            <LegacyCard.Section>
              <div className='flex justify-center items-center'>
                <img
                  src={Logo}
                  className="w-[300px]"
                />
              </div>
              <Form>
                <TextField
                  value={shop}
                  onChange={handleChange}
                  label={t('store')}
                  placeholder="Example: xxxxx.myshopify.com"
                  autoComplete="off"
                />
              </Form>
            </LegacyCard.Section>
          </LegacyCard>
        </div>
      </section>
    )
  }
}

export default LoginPage
