import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import CallbackPage from './pages/CallbackPage'
import AdminLoginPage from './pages/admin/AdminLoginPage'
import AdminPage from './pages/admin/AdminPage'
import TaskPage from './pages/admin/TaskPage'
import ErrorPage from './pages/ErrorPage'
import Main from './pages/Main'
import ProtectedRoute from './components/ProtectedRoute'
import Privacy from './pages/Privacy'
import History from './pages/History'
import Help from './pages/Help'
import Pricing from './pages/Pricing'
import SoockerEntry from './pages/SoockerEntry'
import WooCommerceLoginPage from './pages/WooCommerceLoginPage'

export default function App () {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/callback' element={<CallbackPage />} />
        <Route path='/soocker/login' element={<SoockerEntry />} />
        <Route path='/admin/login' element={<AdminLoginPage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/admin/tasks' element={<TaskPage />} />
        <Route path='/error' element={<ErrorPage />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/help' element={<Help />} />
        <Route path='/history' element={<History />} />
        <Route path='/woo_commerce_login' element={<WooCommerceLoginPage />} />
        <Route path='/' element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  )
}
