const shopifyTableDataSources = (t) => [
  {
    key: '1',
    function: t('pricingContent:term'),
    trial: t('pricingContent:unlimited'),
    paid: t('pricingContent:subscription')
  },
  {
    key: '2',
    function: '費用',
    trial: t('pricingContent:free'),
    paid: t('pricingContent:price_usd')
  },
  {
    key: 'subscription-button',
    function: ' ',
    trial: t('pricingContent:partial_30_days_only'),
    paid: '月繳 年繳 兩個按鈕'
  },
  {
    key: '3',
    function: t('pricingContent:orca_dashboard'),
    trial: '',
    paid: ''
  },
  {
    key: '4',
    function: t('pricingContent:upload_data'),
    trial: t('pricingContent:unlimited_30_days'),
    paid: t('pricingContent:unlimited_time')
  },
  {
    key: '5',
    function: t('pricingContent:orca_algorithms'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '6',
    function: t('pricingContent:auto_update_data'),
    trial: '-',
    paid: t('pricingContent:auto_update_3_days')
  },
  {
    key: '7',
    function: t('pricingContent:manual_update_data'),
    trial: t('pricingContent:up_to_3_times'),
    paid: t('pricingContent:manually_update')
  },
  {
    key: '8',
    function: t('pricingContent:download_labels'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '9',
    function: t('pricingContent:marketing_automation'),
    trial: '',
    paid: ''
  },
  {
    key: '10',
    function: t('pricingContent:send_emails'),
    trial: t('pricingContent:email_cost'),
    paid: t('pricingContent:email_cost')
  },
  {
    key: '11',
    function: t('pricingContent:send_sms'),
    trial: t('pricingContent:sms_cost_30_days'),
    paid: t('pricingContent:sms_cost')
  },
  {
    key: '12',
    function: t('pricingContent:send_line'),
    trial: t('pricingContent:line_msg_cost_30_days'),
    paid: t('pricingContent:line_msg_cost')
  },
  {
    key: '13',
    function: t('pricingContent:sync_labels'),
    trial: t('pricingContent:auto_sync_labels_30_days'),
    paid: t('pricingContent:latest_labels')
  },
  {
    key: '14',
    function: t('pricingContent:upload_custom_labels'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '15',
    function: t('pricingContent:manual_create_list'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '16',
    function: t('pricingContent:batch_import_list'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '17',
    function: t('pricingContent:create_blacklist'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '18',
    function: t('pricingContent:create_customer_groups'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '19',
    function: t('pricingContent:ga4_remarketing'),
    trial: ' ',
    paid: 'V'
  },
  {
    key: '20',
    function: t('pricingContent:short_url'),
    trial: t('pricingContent:30_days_only'),
    paid: 'V'
  }
]

const antdTableDataSource = (t) => [
  {
    key: '1',
    function: t('pricingContent:term'),
    trial: t('pricingContent:30_days'),
    paid: t('pricingContent:subscription')
  },
  {
    key: '2',
    function: t('pricingContent:cost'),
    trial: t('pricingContent:free_trial'),
    paid: t('pricingContent:price_ntd')
  },
  {
    key: 'subscription-button',
    function: ' ',
    trial: ' ',
    paid: '訂購連結'
  },
  {
    key: '3',
    function: t('pricingContent:orca_dashboard'),
    trial: '',
    paid: ''
  },
  {
    key: '4',
    function: t('pricingContent:upload_data'),
    trial: t('pricingContent:unlimited_30_days'),
    paid: t('pricingContent:unlimited_time')
  },
  {
    key: '5',
    function: t('pricingContent:orca_algorithms'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '6',
    function: t('pricingContent:auto_update_data'),
    trial: '-',
    paid: t('pricingContent:auto_update_3_days')
  },
  {
    key: '7',
    function: t('pricingContent:manual_update_data'),
    trial: t('pricingContent:up_to_3_times'),
    paid: t('pricingContent:manually_update')
  },
  {
    key: '8',
    function: t('pricingContent:download_labels'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '9',
    function: t('pricingContent:marketing_automation'),
    trial: '',
    paid: ''
  },
  {
    key: '10',
    function: t('pricingContent:send_emails'),
    trial: t('pricingContent:email_cost'),
    paid: t('pricingContent:email_cost')
  },
  {
    key: '11',
    function: t('pricingContent:send_sms'),
    trial: t('pricingContent:sms_cost'),
    paid: t('pricingContent:sms_cost')
  },
  {
    key: '12',
    function: t('pricingContent:send_line'),
    trial: t('pricingContent:line_msg_cost'),
    paid: t('pricingContent:line_msg_cost')
  },
  {
    key: '13',
    function: t('pricingContent:sync_labels'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '14',
    function: t('pricingContent:upload_custom_labels'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '15',
    function: t('pricingContent:manual_create_list'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '16',
    function: t('pricingContent:batch_import_list'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '17',
    function: t('pricingContent:create_blacklist'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '18',
    function: t('pricingContent:create_customer_groups'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '19',
    function: t('pricingContent:ga4_remarketing'),
    trial: 'V',
    paid: 'V'
  },
  {
    key: '20',
    function: t('pricingContent:short_url'),
    trial: 'V',
    paid: 'V'
  }
]

// 目前只適用shopline, cyberbiz, shopify
const getAntdTableDataSource = (platform, appPayMode, planName, t) => {
  if (platform === '') return []
  if (platform === 'shopline') {
    return antdTableDataSource(t)
  }
  if (platform === 'cyberbiz') {
    const aidaRow = {
      key: 'aida',
      function: t('pricingContent:aida'),
      trial: 'V',
      paid: 'V'
    }
    return [...antdTableDataSource(t), aidaRow]
  }
  if (platform === 'shopify') {
    if (appPayMode === 'subscribe') {
      const unSubscribeRow = {
        key: 'un-subscription-button',
        function: ' ',
        trial: ' ',
        // paid: `您正在訂閱 [${planName}]` // todo: 不確定這邊為什麼不直接寫死為付費版？還是應該要寫月繳/年繳？
        paid: `${t('pricingContent:current_version')} [${t('pricingContent:paid')}]`
      }
      return [...shopifyTableDataSources(t), unSubscribeRow]
    }
    return shopifyTableDataSources(t)
  }
  console.log('warning: 需根據不同平台提供表格內容')
  return []
}

export {
  getAntdTableDataSource
}
