import React from 'react'
import { Banner } from '@shopify/polaris'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

/**
 * 1. 免費方案：不需要告知目前是免費方案，持續顯示 - 試用期已過
 * 2. 試用方案：最後七天開始顯示 - 試用期還剩下X天
 * 3. 付費方案：不顯示
 */

const CTABanner = ({ appPayMode, trialDayLeft }) => {
  const href = window.location.href
  const { t } = useTranslation('ctaBanner')

  const getBannerTitle = (appPayMode, trialDayLeft) => {
    if (appPayMode === 'free' && trialDayLeft === 0) {
      return t('trial_over')
    } else if (appPayMode === 'free' && trialDayLeft > 0) {
      return t('trial_remaining', { trialDayLeft })
    }
    return ''
  }

  const title = getBannerTitle(appPayMode, trialDayLeft)

  return (appPayMode === 'free' && trialDayLeft <= 7)
    ? <Banner
        title={title}
        tone="info"
        action={{ content: t('subscription_plan'), url: `${href}pricing` }}
      >
        <p>
          {t('continue_subscribe')}
        </p>
      </Banner>
    : null
}

CTABanner.propTypes = {
  appPayMode: PropTypes.string.isRequired,
  trialDayLeft: PropTypes.number.isRequired
}

export default CTABanner
