import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@shopify/polaris/build/esm/styles.css'
import { AppProvider } from '@shopify/polaris'
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import './locales/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </ConfigProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
