import twMain from './languages/zh-TW/pages/Main.json'
import twPage0 from './languages/zh-TW/pages/Page0.json'
import twPage1 from './languages/zh-TW/pages/Page1.json'
import twPage2 from './languages/zh-TW/pages/Page2.json'
import twPage3 from './languages/zh-TW/pages/Page3.json'
import twPage4 from './languages/zh-TW/pages/Page4.json'
import twPage5 from './languages/zh-TW/pages/Page5.json'
import twPage6 from './languages/zh-TW/pages/Page6.json'
import twPageAida from './languages/zh-TW/pages/PageAida.json'
import twPricing from './languages/zh-TW/pages/Pricing.json'
import twPrivacy from './languages/zh-TW/pages/Privacy.json'
import twHelp from './languages/zh-TW/pages/Help.json'
import twLoginPage from './languages/zh-TW/pages/LoginPage.json'
import twCallbackPage from './languages/zh-TW/pages/CallbackPage.json'
import twErrorPage from './languages/zh-TW/pages/ErrorPage.json'
import twSoockerEntry from './languages/zh-TW/pages/SoockerEntry.json'
import twPricingContent from './languages/zh-TW/assets/pricing-content.json'
import twLanguageSwitcher from './languages/zh-TW/components/LanguageSwitcher.json'
import twCTABanner from './languages/zh-TW/components/CTABanner.json'
import twDropZone from './languages/zh-TW/components/DropZone.json'
import twEditSegmentsModal from './languages/zh-TW/components/EditSegmentsModal.json'
import twHelpModal from './languages/zh-TW/components/HelpModal.json'
import twOrcaHistoryTable from './languages/zh-TW/components/OrcaHistoryTable.json'
import twPage1Modal from './languages/zh-TW/components/Page1Modal.json'
import twPrivacyModal from './languages/zh-TW/components/PrivacyModal.json'

import enMain from './languages/en/pages/Main.json'
import enPage0 from './languages/en/pages/Page0.json'
import enPage1 from './languages/en/pages/Page1.json'
import enPage2 from './languages/en/pages/Page2.json'
import enPage3 from './languages/en/pages/Page3.json'
import enPage4 from './languages/en/pages/Page4.json'
import enPage5 from './languages/en/pages/Page5.json'
import enPage6 from './languages/en/pages/Page6.json'
import enPageAida from './languages/en/pages/PageAida.json'
import enPricing from './languages/en/pages/Pricing.json'
import enPrivacy from './languages/en/pages/Privacy.json'
import enHelp from './languages/en/pages/Help.json'
import enLoginPage from './languages/en/pages/LoginPage.json'
import enCallbackPage from './languages/en/pages/CallbackPage.json'
import enErrorPage from './languages/en/pages/ErrorPage.json'
import enSoockerEntry from './languages/en/pages/SoockerEntry.json'
import enPricingContent from './languages/en/assets/pricing-content.json'
import enLanguageSwitcher from './languages/en/components/LanguageSwitcher.json'
import enCTABanner from './languages/en/components/CTABanner.json'
import enDropZone from './languages/en/components/DropZone.json'
import enEditSegmentsModal from './languages/en/components/EditSegmentsModal.json'
import enHelpModal from './languages/en/components/HelpModal.json'
import enOrcaHistoryTable from './languages/en/components/OrcaHistoryTable.json'
import enPage1Modal from './languages/en/components/Page1Modal.json'
import enPrivacyModal from './languages/en/components/PrivacyModal.json'

import jpMain from './languages/jp/pages/Main.json'
import jpPage0 from './languages/jp/pages/Page0.json'
import jpPage1 from './languages/jp/pages/Page1.json'
import jpPage2 from './languages/jp/pages/Page2.json'
import jpPage3 from './languages/jp/pages/Page3.json'
import jpPage4 from './languages/jp/pages/Page4.json'
import jpPage5 from './languages/jp/pages/Page5.json'
import jpPage6 from './languages/jp/pages/Page6.json'
import jpPageAida from './languages/jp/pages/PageAida.json'
import jpPricing from './languages/jp/pages/Pricing.json'
import jpPrivacy from './languages/jp/pages/Privacy.json'
import jpHelp from './languages/jp/pages/Help.json'
import jpLoginPage from './languages/jp/pages/LoginPage.json'
import jpCallbackPage from './languages/jp/pages/CallbackPage.json'
import jpErrorPage from './languages/jp/pages/ErrorPage.json'
import jpSoockerEntry from './languages/jp/pages/SoockerEntry.json'
import jpPricingContent from './languages/jp/assets/pricing-content.json'
import jpLanguageSwitcher from './languages/jp/components/LanguageSwitcher.json'
import jpCTABanner from './languages/jp/components/CTABanner.json'
import jpDropZone from './languages/jp/components/DropZone.json'
import jpEditSegmentsModal from './languages/jp/components/EditSegmentsModal.json'
import jpHelpModal from './languages/jp/components/HelpModal.json'
import jpOrcaHistoryTable from './languages/jp/components/OrcaHistoryTable.json'
import jpPage1Modal from './languages/jp/components/Page1Modal.json'
import jpPrivacyModal from './languages/jp/components/PrivacyModal.json'

const resources = {
  'zh-TW': {
    main: twMain,
    page0: twPage0,
    page1: twPage1,
    page2: twPage2,
    page3: twPage3,
    page4: twPage4,
    page5: twPage5,
    page6: twPage6,
    pageAida: twPageAida,
    pricing: twPricing,
    privacy: twPrivacy,
    help: twHelp,
    loginPage: twLoginPage,
    callbackPage: twCallbackPage,
    errorPage: twErrorPage,
    soockerEntry: twSoockerEntry,
    pricingContent: twPricingContent,
    languageSwitcher: twLanguageSwitcher,
    ctaBanner: twCTABanner,
    dropZone: twDropZone,
    editSegmentsModal: twEditSegmentsModal,
    helpModal: twHelpModal,
    orcaHistoryTable: twOrcaHistoryTable,
    page1Modal: twPage1Modal,
    privacyModal: twPrivacyModal
  },
  en: {
    main: enMain,
    page0: enPage0,
    page1: enPage1,
    page2: enPage2,
    page3: enPage3,
    page4: enPage4,
    page5: enPage5,
    page6: enPage6,
    pageAida: enPageAida,
    pricing: enPricing,
    privacy: enPrivacy,
    help: enHelp,
    loginPage: enLoginPage,
    callbackPage: enCallbackPage,
    errorPage: enErrorPage,
    soockerEntry: enSoockerEntry,
    pricingContent: enPricingContent,
    languageSwitcher: enLanguageSwitcher,
    ctaBanner: enCTABanner,
    dropZone: enDropZone,
    editSegmentsModal: enEditSegmentsModal,
    helpModal: enHelpModal,
    orcaHistoryTable: enOrcaHistoryTable,
    page1Modal: enPage1Modal,
    privacyModal: enPrivacyModal
  },
  jp: {
    main: jpMain,
    page0: jpPage0,
    page1: jpPage1,
    page2: jpPage2,
    page3: jpPage3,
    page4: jpPage4,
    page5: jpPage5,
    page6: jpPage6,
    pageAida: jpPageAida,
    pricing: jpPricing,
    privacy: jpPrivacy,
    help: jpHelp,
    loginPage: jpLoginPage,
    callbackPage: jpCallbackPage,
    errorPage: jpErrorPage,
    soockerEntry: jpSoockerEntry,
    pricingContent: jpPricingContent,
    languageSwitcher: jpLanguageSwitcher,
    ctaBanner: jpCTABanner,
    dropZone: jpDropZone,
    editSegmentsModal: jpEditSegmentsModal,
    helpModal: jpHelpModal,
    orcaHistoryTable: jpOrcaHistoryTable,
    page1Modal: jpPage1Modal,
    privacyModal: jpPrivacyModal
  }
}

export default resources
