import { React } from 'react'
import PropTypes from 'prop-types'
import { Modal, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const Page1Modal = (props) => {
  const { active, handleChange, handleTabChange } = props
  const { t } = useTranslation('page1Modal')

  const handleNextPage = async () => {
    handleTabChange(2)
  }

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={t('data_changed')}
      primaryAction={{
        content: t('return'),
        onAction: handleChange
      }}
      secondaryActions={[
        {
          content: t('skip'),
          onAction: handleNextPage
        }
      ]}
    >
      <Modal.Section>
        <Text variant="headingSm">
          {t('save_data')}
        </Text>
      </Modal.Section>
    </Modal>
  )
}

Page1Modal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired
}

export default Page1Modal
