import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { instance } from '../helper'
import { useTranslation } from 'react-i18next'

const CallbackPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('callbackPage')

  useEffect(() => {
    const queryString = window.location.search
    instance({
      method: 'GET',
      url: `/shopify/callback${queryString}`
    })
      .then((res) => {
        window.localStorage.setItem('shop', res.data.shop)
        window.localStorage.setItem('accessToken', res.data.access_token)
        window.location.href = '/'
      })
      .catch(() => navigate('/error'))
  }, [])

  return (
    <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ fontSize: '50px' }}>{t('authenticating')}</h1>
      </div>
    </div>
  )
}

export default CallbackPage
