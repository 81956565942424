import React, { useState } from 'react'
import { Button, Form, FormLayout, TextField } from '@shopify/polaris'
import { authInstance } from '../../helper'
import Logo from '../../assets/orca-logo.png'

const AdminLoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = (value) => setEmail(value)
  const handlePasswordChange = (value) => setPassword(value)

  const login = async () => {
    try {
      const res = await authInstance.post('/admin/login', { email, password })
      const accessToken = res.data.access_token
      window.localStorage.setItem('adminToken', accessToken)
      window.location.href = '/admin/'
    } catch (error) {
      console.log(error.response)
      setErrorMessage(error.response.data.error_message)
    }
  }

  return (
    <section className="h-screen flex items-center justify-center bg-slate-300">
      <div className='w-5/6 md:w-1/2 lg:w-1/3 px-5 py-16 rounded-3xl shadow-lg bg-slate-100'>
        <div className="flex justify-center">
          <img
            src={Logo}
            className="w-[300px]"
          />
        </div>
        <Form onSubmit={login}>
          <FormLayout>
            <TextField
              value={email}
              onChange={handleEmailChange}
              label="Email"
              type="email"
            />
            <TextField
              value={password}
              onChange={handlePasswordChange}
              label="Password"
              type="password"
            />
            {(errorMessage) && <div className='text-red-600'>{errorMessage}</div>}
            <Button submit>登入</Button>
          </FormLayout>
        </Form>
      </div>
    </section>
  )
}

export default AdminLoginPage
