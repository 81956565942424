import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Button, Layout, Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import Logo from '../../../assets/orca-logo.png'

const { Header, Content, Footer } = Layout

const items = [
  {
    key: '1',
    label: (<NavLink to="/admin/" className="hover:text-gray-800">
      商店一覽
    </NavLink>)
  },
  {
    key: '2',
    label: (<NavLink to="/admin/tasks" className="hover:text-gray-800">
      排程項目
    </NavLink>)
  }
]

const CustomizedLayout = (props) => {
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgb(219, 234, 254)'
        }}
      >
        <div>
          <img
            src={Logo}
            alt="ORCA"
            style={{ width: '50px', height: '50px', marginRight: '10px', display: 'inline-block' }}
          />
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>ORCA App 管理員後台</span>
        </div>
        <Menu
          mode="horizontal"
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: 'rgb(219, 234, 254)'
          }}
        />
        <div style={{ position: 'absolute', right: '30px' }}>
          <Button
            onClick={() => {
              window.localStorage.removeItem('adminToken')
              window.location.href = '/admin/login'
            }}
            type="text"
            shape="round"
            icon={<LogoutOutlined />}
          >
            登出
          </Button>
        </div>
      </Header>
      <Content
        style={{
          padding: '24px 48px'
        }}
      >
        {props.children}
      </Content>
      <Footer
        style={{
          textAlign: 'center'
        }}
      >
        版權所有 ©{new Date().getFullYear()} 潮網科技股份有限公司
      </Footer>
    </Layout>
  )
}

CustomizedLayout.propTypes = {
  children: PropTypes.element
}

export default CustomizedLayout
