import { React, useState, useEffect, useCallback } from 'react'
import {
  LegacyCard,
  Text,
  Button,
  Checkbox,
  BlockStack,
  InlineStack,
  SkeletonBodyText,
  Toast
} from '@shopify/polaris'
import { authInstance } from '../helper'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PageAida = ({ handleTabChange, appPayMode, trialDayLeft }) => {
  const [projectState, setProjectState] = useState(-1)
  const [showRecomOnProductPage, setShowRecomOnProductPage] = useState(true)
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [toastContent, setToastContent] = useState('')
  const { t } = useTranslation('pageAida')
  // const [syncProductBtnEnabled, setSyncProductBtnEnabled] = useState(true)

  const isPageActive = (appPayMode === 'subscribe') || (trialDayLeft > 0)

  // const getAidaUpdateTime = async () => {
  //   try {
  //     const res = await authInstance.get('/aida/info')
  //     return res.data.aida_update_time
  //   } catch (err) {
  //     console.log('getAidaUpdateTime err:', err)
  //   }
  // }

  // const checkSyncProductBtnEnabled = (aidaLastUpdateTime, gapDay) => {
  //   const now = new Date()
  //   const diff = now - new Date(aidaLastUpdateTime)
  //   const diffHours = diff / 1000 / 60 / 60
  //   return diffHours > gapDay * 24
  // }

  const handleCheckboxChange = useCallback(
    (newChecked) => setShowRecomOnProductPage(newChecked), []
  )

  const getAidaSetting = async () => {
    try {
      const res = await authInstance.get('/aida/settings', {})
      return res.data.is_active
    } catch (err) {
      console.log('getAidaSetting err:', err)
    }
  }

  const asyncForUseEffect = async () => {
    const res = await authInstance.get('/get_shop_info')
    /*
    0 = 基本資料未設定
    1 = 基本資料已設定
    2 = 自動拋轉資料到 ORCA 進行中
    3 = 自動拋轉資料到 ORCA 完成
    4 = 已送出分析請求但未完成
    5 = 分析完成
    */
    let tempState = 0
    if (res.data.orca_state === null) {
      setProjectState(0)
      return
    } else {
      const orcaState = res.data.orca_state
      if (orcaState === 0) {
        setProjectState(0)
        return
      } else if (orcaState === 1) {
        tempState = 1
      } else if (orcaState === 2) {
        tempState = 2
      } else if (orcaState === 3) {
        tempState = 2
      } else if (orcaState === 4) {
        tempState = 2
      } else if (orcaState === 5) {
        tempState = 2
      }
    }
    setProjectState(tempState)
  }

  useEffect(() => {
    asyncForUseEffect()
    const updateAidaSetting = async () => {
      const isActive = await getAidaSetting()
      setShowRecomOnProductPage(isActive)
    }
    updateAidaSetting()
    // if (appPayMode === 'subscribe') {
    //   const checkButtonEnabled = async () => {
    //     const aidaLastUpdateTime = await getAidaUpdateTime()
    //     const syncBtnEnabled = checkSyncProductBtnEnabled(aidaLastUpdateTime, 1)
    //     setSyncProductBtnEnabled(syncBtnEnabled)
    //   }
    //   checkButtonEnabled()
    // }
  }, [])

  const saveRecomSetting = async () => {
    try {
      await authInstance.post('/aida/settings', {
        is_active: showRecomOnProductPage
      })
    } catch (err) {
      console.log('saveRecomSetting err:', err)
    }
  }

  const handleSaveSettings = () => {
    saveRecomSetting()
    setToastContent(t('settings_saved'))
    setOpenSuccessBanner(true)
  }

  // const handleSyncProductManually = async () => {
  //   try {
  //     await authInstance.get('/aida/update-product-data', {})
  //     setToastContent('推薦商品計算中')
  //     setOpenSuccessBanner(true)
  //   } catch (err) {
  //     console.log('handleSyncProductManually err:', err)
  //   }
  //   setSyncProductBtnEnabled(false)
  // }

  return (
    <div>
      {(projectState === -1) &&
        <LegacyCard.Section>
          <SkeletonBodyText lines={10} />
        </LegacyCard.Section>
      }
      {(projectState === 0) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="center">
            <Text variant="headingSm">
              {t('basic_info_missing')}
            </Text>
            <Button onClick={() => handleTabChange(1)}>{t('fill_in_basic_information')}</Button>
          </BlockStack>
        </LegacyCard.Section>
      }
      {(projectState === 1 || projectState === 2) &&
      <div>
        <LegacyCard.Section>
          <BlockStack gap="2">
            <Text as="h1" variant="headingXl">
              {t('recommended_display')}
            </Text>
            {/* 愛搭每24h更新資料，所以就不用給手動按更新功能了(原本就是設計24h內只能按一次) */}
            {/* {appPayMode === 'subscribe' &&
            <>
              <HorizontalStack gap="3" align="end">
                <Button
                  onClick={handleSyncProductManually}
                  // 在「上次更新時間未超過gapDay」或「未開啟推薦功能」的條件下，按鈕就disabled
                  disabled={!syncProductBtnEnabled || !showRecomOnProductPage}>立即重新計算推薦商品</Button>
              </HorizontalStack>
              <HorizontalStack gap="3" align="end">
                <Text as="p" tone="subdued">
                  24小時內僅能執行一次
                </Text>
              </HorizontalStack>
            </>} */}
            <BlockStack gap="5">
              <BlockStack gap="2">
                <Text as="p" variant="bodyLg">
                  {t('aida_intro')}
                </Text>
                <Text as="p" variant="bodyLg">
                  {t('first_activation')}
                </Text>
                <Text as="p" variant="bodyLg">
                  {t('cancel_recommendation')}
                </Text>
              </BlockStack>
              <Text as="p" variant="bodyLg">
                <Checkbox
                  label={t('display_product_page')}
                  checked={showRecomOnProductPage}
                  onChange={handleCheckboxChange}
                  disabled={!isPageActive}
                />
              </Text>
            </BlockStack>
            <InlineStack align="end">
              <Button
                onClick={handleSaveSettings}
                disabled={!isPageActive}>{t('save_settings')}</Button>
            </InlineStack>
          </BlockStack>
        </LegacyCard.Section>
        {openSuccessBanner &&
          <Toast content={toastContent} duration={8000}
            onDismiss={() => { setOpenSuccessBanner(false) }} />}
        </div>
      }
    </div>
  )
}

PageAida.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  appPayMode: PropTypes.string.isRequired,
  trialDayLeft: PropTypes.number
}

export default PageAida
