import { React, useState, useEffect } from 'react'
import { DataTable } from '@shopify/polaris'
import { getOrcaHistory, showTime } from '../helper'
import { useTranslation } from 'react-i18next'

const OrcaHistoryTable = () => {
  const [rowData, setRowData] = useState([])
  const { t } = useTranslation('orcaHistoryTable')

  const loadHistory = async () => {
    const res = await getOrcaHistory()
    const jData = res?.data?.data || []
    const tempData = []
    for (let i = 0; i < jData.length; i++) {
      tempData.push([
        jData[i].id,
        showTime(jData[i].create_time),
        showTime(jData[i].edit_time),
        jData[i].status
      ])
    }
    setRowData(tempData)
  }
  useEffect(() => {
    loadHistory()
  }, [])

  /*
  const rows = [
    ['wavenet-app-dev20238101741494', '2023-08-10T09:41:49', '2023-08-10T09:48:15', 'finish'],
    ['wavenet-app-dev2023810179381', '2023-08-10T09:09:38', '2023-08-10T09:15:43', 'finish']
  ]
  */
  /*
  {
      "data": [
          {
              "create_time": "2023-08-10T09:41:49.411988Z",
              "edit_time": "2023-08-10T09:48:15.709557Z",
              "id": "wavenet-app-dev20238101741494",
              "status": "finish"
          },
          {
              "create_time": "2023-08-10T09:09:38.165896Z",
              "edit_time": "2023-08-10T09:15:43.192103Z",
              "id": "wavenet-app-dev2023810179381",
              "status": "finish"
          },
          {
              "create_time": "2023-08-10T08:55:18.645411Z",
              "edit_time": "2023-08-10T09:01:33.572827Z",
              "id": "wavenet-app-dev20238101655186",
              "status": "finish"
          }
      ]
  }
  */

  return (
    ((rowData.length > 0)
      ? <DataTable
        columnContentTypes={[
          'text',
          'text',
          'text',
          'text'
        ]}
        headings={[
          t('id'),
          t('create_time'),
          t('modify_time'),
          t('status')
        ]}
        rows={rowData}
      />
      : <div className='flex justify-center'>
        <p className='text-lg font-bold'>{t('no_data')}</p>
      </div>
    )
  )
}

export default OrcaHistoryTable
